import Approot from "./app-root-component";
import { connect } from "react-redux";

const mapDispatchToProps = {  };

const mapStateToProps = (state) => ({
    vendor: state.vendorPage.loggedVendor,
    isLoggedIn: state.vendorPage.isLoggedIn,
   
});

export default connect(mapStateToProps, {})(Approot);
