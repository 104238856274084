export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const CREATE_PRODUCT_FAILURE = "CREATE_PRODUCT_FAILURE";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";

export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAILURE";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";

export const GET_PRODUCT = "GET_PRODUCT";
export const GET_PRODUCT_FAILURE = "GET_PRODUCT_FAILURE";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";

export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
