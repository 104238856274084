import *  as ActionTypes from "./productActionTypes";

const initialState = {
    isLoading: false,
    isSaved: false,
    products: [],
    count: 0
};

export const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_PRODUCT:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.CREATE_PRODUCT_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.CREATE_PRODUCT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.DELETE_PRODUCT:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.DELETE_PRODUCT_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.DELETE_PRODUCT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.GET_PRODUCT:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_PRODUCT_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_PRODUCT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                products: action?.payload?.data?.data || [],
                count: action?.payload?.data?.count || 0
            }
        case ActionTypes.UPDATE_PRODUCT:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.UPDATE_PRODUCT_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.UPDATE_PRODUCT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        default:
            return state;
    }
}