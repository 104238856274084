import *  as ActionTypes from "./vendorActionTypes";

const initialState = {
    isLoading: false,
    isSaved: false,
    vendors: [],
    isLoggedIn: sessionStorage.getItem('loggedVendor') !== null ? true : false,
    loggedVendor: sessionStorage.getItem('loggedVendor') !== null ? JSON.parse(sessionStorage.getItem('loggedVendor')) : {}
};

export const vendorReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.VENDOR_LOGIN:
            return {
                ...state,
                isLoading: true,
                isLoggedIn: false
            };
        case ActionTypes.VENDOR_LOGIN_FAILURE:
            return {
                ...state,
                isLoading: false,
                isLoggedIn: false
            };
        case ActionTypes.VENDOR_LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoggedIn: true,
                loggedVendor: action.payload ? action.payload.data : {},
            };
        case ActionTypes.CREATE_VENDOR:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.CREATE_VENDOR_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.CREATE_VENDOR_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.DELETE_VENDOR:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.DELETE_VENDOR_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.DELETE_VENDOR_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.UPDATE_VENDOR:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.UPDATE_VENDOR_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.UPDATE_VENDOR_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.GET_CURRENT:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            };
        case ActionTypes.GET_CURRENT_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.GET_CURRENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                // isSaved: true,
                vendors: action?.payload?.data || [],
            };
        default:
            return state;
    }
}