export const CREATE_VENDOR = "CREATE_VENDOR";
export const CREATE_VENDOR_FAILURE = "CREATE_VENDOR_FAILURE";
export const CREATE_VENDOR_SUCCESS = "CREATE_VENDOR_SUCCESS";

export const DELETE_VENDOR = "DELETE_VENDOR";
export const DELETE_VENDOR_FAILURE = "DELETE_VENDOR_FAILURE";
export const DELETE_VENDOR_SUCCESS = "DELETE_VENDOR_SUCCESS";

export const UPDATE_VENDOR = "UPDATE_VENDOR";
export const UPDATE_VENDOR_FAILURE = "UPDATE_VENDOR_FAILURE";
export const UPDATE_VENDOR_SUCCESS = "UPDATE_VENDOR_SUCCESS";

export const GET_CURRENT = "GET_CURRENT";
export const GET_CURRENT_FAILURE = "GET_CURRENT_FAILURE";
export const GET_CURRENT_SUCCESS = "GET_CURRENT_SUCCESS";

export const VENDOR_LOGIN = "VENDOR_LOGIN";
export const VENDOR_LOGIN_FAILURE = "VENDOR_LOGIN_FAILURE";
export const VENDOR_LOGIN_SUCCESS = "VENDOR_LOGIN_SUCCESS";