import *  as ActionTypes from "./categoryActionTypes";

const initialState = {
    isLoading: false,
    isSaved: false,
    categoryes: [],
    parentCategories: []
};

export const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_CATEGORY:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.CREATE_CATEGORY_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.CREATE_CATEGORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.DELETE_CATEGORY:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.DELETE_CATEGORY_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.DELETE_CATEGORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.GET_CATEGORY:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_CATEGORY_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_CATEGORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                categoryes: action?.payload?.data || []
            }
        case ActionTypes.GET_PARENT_CATEGORY:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_PARENT_CATEGORY_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_PARENT_CATEGORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                parentCategories: action?.payload?.data || []
            }
        case ActionTypes.UPDATE_CATEGORY:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.UPDATE_CATEGORY_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.UPDATE_CATEGORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        default:
            return state;
    }
}