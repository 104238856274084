export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const CREATE_CATEGORY_FAILURE = "CREATE_CATEGORY_FAILURE";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";

export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const DELETE_CATEGORY_FAILURE = "DELETE_CATEGORY_FAILURE";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";

export const GET_CATEGORY = "GET_CATEGORY";
export const GET_CATEGORY_FAILURE = "GET_CATEGORY_FAILURE";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";

export const GET_PARENT_CATEGORY = "GET_PARENT_CATEGORY";
export const GET_PARENT_CATEGORY_FAILURE = "GET_PARENT_CATEGORY_FAILURE";
export const GET_PARENT_CATEGORY_SUCCESS = "GET_PARENT_CATEGORY_SUCCESS";

export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const UPDATE_CATEGORY_FAILURE = "UPDATE_CATEGORY_FAILURE";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
